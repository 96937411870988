import axios from 'axios';

const instance =  axios.create({
    //baseURL:'https://falconsoft.com.mx:3117'
    baseURL:'http://3.140.155.49:3017'
    //baseURL:'http://localhost:3017'
});

instance.interceptors.request.use(
    async config => {

      return config;
    },
    err => {
      return Promise.reject(err);
    }
  );

export default instance;