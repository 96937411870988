import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
//import "./styles.css";

import { Provider as AccountProvider } from './context/AccountContext';

import RequestAccountScreen from './screens/RequestAccountScreen';

function App() {
  return (
    <BrowserRouter>
      <AccountProvider>

        <Routes>
          <Route path="/" element={<RequestAccountScreen />}></Route>
          <Route path='request_account/:bid' element={<RequestAccountScreen />} />
          <Route path="*" element={<div className='not_found'>Not Found</div>} />
        </Routes>

      </AccountProvider>
    </BrowserRouter>
  );
}

export default App;
