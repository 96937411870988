import axios from 'axios';

const instance =  axios.create({
    //baseURL:'https://shopmay.com.mx:3115'
    baseURL:'http://3.140.155.49:3015'
    //baseURL:'http://localhost:3015'
});

instance.interceptors.request.use(
    async config => {

      return config;
    },
    err => {
      return Promise.reject(err);
    }
  );

export default instance;