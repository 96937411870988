import React, { useContext, useState } from "react";
import { Context as AccountContext } from '../context/AccountContext';
import { useParams } from 'react-router-dom';
import "../css/account.css";
import image from '../assets/account.jpg';
import logoeb02 from '../assets/eb02.png';
import logoeb05 from '../assets/eb05.png';

import logofb01 from '../assets/fb01.png';

import logoDefault from '../assets/default.png';


const RequestAccountScreen = () => {
    const params = useParams();
    const { state: { status, message }, SendRequestDelete } = useContext(AccountContext);
    const [form, setForm] = useState({ user: '' });
    const [showActivity, setShowActivity] = useState(false);
    const [error, setError] = useState('');


    const handleChange = ({ target: { value, name } }) => {
        setForm({ ...form, [name]: value });
        setError('');
    }

    let title;
    let logo;
    let code;
    let system;

    switch (params.bid) {
        case 'eb02':
            title = 'Mauricio Mares';
            logo = logoeb02;
            code = 2;
            system = 'Ecommerce';
            break;
        case 'eb05':
            title = 'Zapatillas May';
            logo = logoeb05;
            code = 5;
            system = 'Ecommerce';
            break;
        case 'fb01':
            title = 'DABASIR';
            logo = logofb01;
            code = 1;
            system = 'Financiera';
            break;
        default:
            title = 'FalconSoft';
            logo = logoDefault;
            system = null;
            break;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (form.user !== "") {
            if (system !== null) {
                form.code = code;
                SendRequestDelete(form, setShowActivity, system);
            }
        } else {
            setError('Escriba el usuario!');
        }
    };


    return (
        <div className="account_main">
            <div className="account_box_left">
                <div className="logoTitle">
                    <img src={logo} alt={''} className="logo" />
                    <div className="textTitle">{title}</div>
                </div>
                <h2>Cuenta</h2>
                <div className="textSubTitle">Ingresa tu usuario para solicitar que se elimine tus datos y tu cuenta del sistema.</div>
                <form onSubmit={handleSubmit}>
                    <div>
                        <input
                            type="number"
                            name="user"
                            id="user"
                            onChange={handleChange}
                            placeholder="Usuario"
                            className="input"
                        />
                    </div>


                    {status === true && <div className="textSuccess">{message}</div>}
                    {status !== true && <div className="textError">{message}</div>}
                    <div className="textError">{error}</div>

                    {status !== true &&
                        <div>
                            <button
                                type="submit"
                                className="button"
                            >
                                Envíar soclicitud
                            </button>
                        </div>
                    }
                </form>
            </div>


            <div className="account_box_right">
                <img src={image} alt={''} className="account_image" />
            </div>


            {showActivity && <div class="backGround">
                <div className="loader"></div>
            </div>}
        </div>
    );
};

export default RequestAccountScreen;