import createDataContext from './createDataContext';
import ApiEcommerce from '../api/ApiEcommerce';
import ApiFinanciera from '../api/ApiFinanciera';
const accountReducer = (state, action) => {
    switch (action.type) {
        case 'SendRequestDelete':
            return { status: action.payload.status, message: action.payload.message };
        default:
            return state;
    }
};

const SendRequestDelete = (dispatch) => {
    return async (req, show, system) => {
        try {
            show(true);
            let response;

            if (system === 'Ecommerce') {
                response = await ApiEcommerce.post('/SendRequestDelete', req);
            } else if (system === 'Financiera') {
                response = await ApiFinanciera.post('/SendRequestDelete', req);
            }



            dispatch({ type: 'SendRequestDelete', payload: response.data });
            show(false);
        } catch (error) {
            show(false);
            if (error.response) {
                dispatch({ type: 'SendRequestDelete', payload: error.response.data });
            }
        }
    };
};

export const { Provider, Context } = createDataContext(
    accountReducer,
    { SendRequestDelete },
    { status: null, message: null }
);
